import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Dropdown, IButtonProps, Icon, Modal, Status, useToast } from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/common/modals/EditApprovalModal.module.sass'
import { IGetAutomationResponse } from '../../models/api/IAutomationRequest'
import { StatusIcon } from '../ManageAutomations/StatusIcon'
import { AutomationApprovalDisplayStates } from '../../enums/AutomationApprovalStates'
import { editApproval } from '../../api/AutomationService'
import { useSetRecoilState } from 'recoil'
import { automationDetailsRefresh } from '../../stores/AutomationViewerStore'
import { useAuth0 } from '@auth0/auth0-react'
import { StatusIconColours } from '../../config/config'
import ConfirmModal from '../ConfirmModal'
import { useUserPermission } from '../../hooks/useUserPermission'
import { actions } from '../../helpers/userPermission'
import { getUserRoles } from '../../helpers/appRoles'

interface EditApprovalProps {
  role: string
  automation: IGetAutomationResponse
}

const EditApproval: FC<EditApprovalProps> = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newStatus, setNewStatus] = useState<string>('')
  const [showEditApprovalForm, setShowEditApprovalForm] = useState(false)
  const automationStateCounter = useSetRecoilState(automationDetailsRefresh)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const { user } = useAuth0()

  const { addToast } = useToast()

  const refreshAutomationDetails = useCallback(() => {
    automationStateCounter((num) => num + 1)
  }, [automationStateCounter])

  const handleEditApproval = useCallback(() => {
    setShowEditApprovalForm(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setShowEditApprovalForm(false)
  }, [])

  const handleSave = useCallback(async () => {
    setIsSubmitting(true)
    const result = await editApproval({
      automationId: props.automation.Id,
      role: props.role,
      status: newStatus,
    })

    if (!result?.success) {
      addToast({
        type: 'error',
        message: result?.message as string,
        timeout: 4000,
        datetime: Date.now(),
      })
      setIsSubmitting(false)
      setShowEditApprovalForm(false)
      setShowConfirmationModal(false)
      return
    }

    addToast({
      type: 'success',
      message: 'Automation status has been updated.',
      timeout: 4000,
      datetime: Date.now(),
    })

    setIsSubmitting(false)
    setShowEditApprovalForm(false)
    setShowConfirmationModal(false)
    refreshAutomationDetails()
  }, [props.automation.Id, props.role, newStatus, refreshAutomationDetails, addToast])

  const roleApproval = useMemo(() => {
    if (!props.automation.AutomationApprovals) return null
    return [...props.automation.AutomationApprovals]
      .filter((approval) => approval.ApproverRole.Name === props.role)
      .sort((a, b) => new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime())[0]
  }, [props.automation.AutomationApprovals, props.role])

  const status: string = useMemo(
    () => roleApproval?.ApprovalState.Name ?? AutomationApprovalDisplayStates.AwaitingApproval,
    [roleApproval],
  )

  const currentUserRole = getUserRoles(user)[0]
  const isUserAllowedToEdit = useUserPermission(actions.CHANGE_APPROVER_ROLE)
  const isApprovalOwner = useMemo(
    () => roleApproval?.ApproverRole.Name === currentUserRole && roleApproval?.ApprovedBy.UserEmail === user?.email,
    [currentUserRole, roleApproval?.ApprovedBy.UserEmail, roleApproval?.ApproverRole.Name, user?.email],
  )
  const canEditApproval = useMemo(() => isUserAllowedToEdit || isApprovalOwner, [isUserAllowedToEdit, isApprovalOwner])

  const getAllowedStatusChanges = useCallback(() => {
    const awaitingApproval = (
      <Status
        type='success'
        colour={StatusIconColours.AwaitingApproval}
        label={AutomationApprovalDisplayStates.AwaitingApproval}
        key={AutomationApprovalDisplayStates.AwaitingApproval}
        size='extra small'
        cssClass={Style.approvalStatus}
      />
    )
    const approved = (
      <Status
        type='success'
        colour={StatusIconColours.Approved}
        label={AutomationApprovalDisplayStates.Approved}
        key={AutomationApprovalDisplayStates.Approved}
        size='extra small'
        cssClass={Style.approvalStatus}
      />
    )

    const actionRequired = (
      <Status
        type='warning'
        colour={StatusIconColours.ActionRequired}
        label={AutomationApprovalDisplayStates.ActionRequired}
        key={AutomationApprovalDisplayStates.ActionRequired}
        size='extra small'
        cssClass={Style.approvalStatus}
      />
    )

    const rejected = (
      <Status
        type='error'
        colour={StatusIconColours.Rejected}
        label={AutomationApprovalDisplayStates.Rejected}
        key={AutomationApprovalDisplayStates.Rejected}
        size='extra small'
        cssClass={Style.approvalStatus}
      />
    )

    const acceptedForTracking = (
      <Status
        type='success'
        colour={StatusIconColours.AcceptedForTracking}
        label={AutomationApprovalDisplayStates.AcceptedForTracking}
        key={AutomationApprovalDisplayStates.AcceptedForTracking}
        size='extra small'
        cssClass={Style.approvalStatus}
      />
    )

    const statusChanges: { [key: string]: JSX.Element[] } = {
      [AutomationApprovalDisplayStates.Approved]: [approved, awaitingApproval],
      [AutomationApprovalDisplayStates.ActionRequired]: [actionRequired, awaitingApproval],
      [AutomationApprovalDisplayStates.Rejected]: [rejected, awaitingApproval],
      [AutomationApprovalDisplayStates.AcceptedForTracking]: [acceptedForTracking, awaitingApproval],
    }

    return statusChanges[status]
  }, [status])

  const items = useMemo(
    () => getAllowedStatusChanges()?.map((i) => ({ id: i.key as string, label: i })),
    [getAllowedStatusChanges],
  )

  useEffect(() => {
    setNewStatus(status)
  }, [status])

  const ModalActions: IButtonProps[] = useMemo(
    () => [
      {
        label: 'Close',
        type: 'primary',
        cssClass: Style.secondaryButton,
        onClick: handleModalClose,
      },
      {
        label: 'Save',
        type: 'secondary',
        cssClass: Style.primaryButton,
        disabled: isSubmitting || status.toLocaleLowerCase() === newStatus.toLocaleLowerCase(),
        onClick: () => {
          setShowConfirmationModal(true)
          setShowEditApprovalForm(false)
        },
      },
    ],
    [handleModalClose, isSubmitting, status, newStatus],
  )

  return (
    <>
      {canEditApproval && status !== AutomationApprovalDisplayStates.AwaitingApproval && (
        <div className={Style.editContainer}>
          <Icon type='edit' size='small' cssClass={Style.editButton} onClick={handleEditApproval} />
        </div>
      )}
      <Modal
        isShowing={showEditApprovalForm}
        actions={ModalActions}
        isCloseButton
        shouldOverlayClose={false}
        onClose={handleModalClose}
        size='medium'
        cssClass={Style.editApprovalModal}
        modalPadding={{
          top: '24px',
          right: '24px',
          left: '24px',
        }}
      >
        <>
          <div className={Style.iconRow}>
            <h2>Edit Status?</h2>
          </div>
          <div className={Style.modalContent}>
            <div className={Style.approverComboBoxContainer}>
              <StatusIcon automation={props.automation} appRole={props.role} size='extra small' showApproverRole />
            </div>
            <div className={Style.dropdownContainer}>
              <div className={Style.statusDrodownlabel}>New Status *</div>
              <Dropdown
                items={items}
                selectedItem={newStatus}
                onSelectItem={(s) => setNewStatus(s.toString())}
                required
                placeholder='Select a Role'
                cssClass={Style.statusDropdown}
              />
            </div>
          </div>
        </>
      </Modal>
      <ConfirmModal
        title={'Warning'}
        message={
          <>
            The approval status will be changed from <span className={Style.status}>{status}</span> to{' '}
            <span className={Style.status}>{newStatus}</span>. Are you sure you want to proceed?
          </>
        }
        open={showConfirmationModal}
        onClose={() => {
          setShowEditApprovalForm(true)
          setShowConfirmationModal(false)
          setIsSubmitting(false)
        }}
        onNo={() => {
          setShowEditApprovalForm(true)
          setShowConfirmationModal(false)
          setIsSubmitting(false)
        }}
        onYes={handleSave}
        loading={isSubmitting}
        isCloseButton
      />
    </>
  )
}

export default EditApproval

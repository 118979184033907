import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import Page from '../components/Page'
import Style from '../styles/AutomationViewer.module.sass'
import { Button, Grid, Icon, useToast } from '@aurecon-creative-technologies/styleguide'
import EstimatedAverageTimeSaved from '../components/AutomationViewer/EstimatedAverageTimeSaved'
import AutomationInformation from '../components/AutomationViewer/AutomationInformation'
import VerificationInformation from '../components/AutomationViewer/VerificationInformation'
import CommentForm from '../components/AutomationViewer/CommentForm'
import { useNavigate, useParams } from 'react-router-dom'
import { dateTimeFormat, dateToString } from '../helpers/utils'
import ErrorModal from '../components/ErrorModal'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { AutomationDetails, automationDetailsRefresh } from '../stores/AutomationViewerStore'
import { AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import LoadingScreen from '../components/LoadingScreen'
import NotFound from './NotFound'
import { useAuth0 } from '@auth0/auth0-react'
import { AutomationStateNames } from '../enums/AutomationStates'
import { AppRoutes } from '../enums/AppRoutes'
import { handleDownload } from '../helpers/commonFunctions'
import { AppRolesEnum, SCRIPT_LIBRARY_VIEW_URL } from '../config/config'
import { AutomationApprovalDisplayStates } from '../enums/AutomationApprovalStates'
import Likes from '../components/Likes'
import { useUserPermission } from '../hooks/useUserPermission'
import { actions } from '../helpers/userPermission'
import ErrorScreen from './ErrorScreen'
import { deactivateAutomation, deleteAutomation, reactivateAutomation } from '../api/AutomationService'
import LoadingModal from '../components/LoadingModal'
import { DeactivatedAutomations, MyContributions, PendingApprovalList } from '../stores/ManageAutomationStore'
import ConfirmModal from '../components/ConfirmModal'
import { IResponse, IResponseTypes } from '../models/api/IResponse'
import { CurrentUser } from '../stores/AppStore'

const AutomationViewer: FC = () => {
  const currentUser = useRecoilValue(CurrentUser)
  const [automation, setAutomation] = useState<IGetAutomationResponse | null>()
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [confirmationModalTitle, setConfirmationModalTitle] = useState<string>('')
  const [confirmationModalMessage, setConfirmationModalMessage] = useState<string | ReactNode>()
  const [confirmationModalAction, setConfirmationModalAction] = useState<number>(0)
  const [loadingModalMessage, setLoadingModalMessage] = useState<string>('Loading...')
  const { automationId, typeId } = useParams<{ automationId: string; typeId: string }>()
  const navigate = useNavigate()
  const { addToast } = useToast()
  const { user } = useAuth0()

  const automationDetails = useRecoilValueLoadable(
    AutomationDetails({ id: String(automationId), typeId: Number(typeId) }),
  )
  const refreshManagePageDeactivated = useRecoilRefresher_UNSTABLE(DeactivatedAutomations)
  const refreshManagePageApprovals = useRecoilRefresher_UNSTABLE(PendingApprovalList)
  const refreshManagePageContributions = useRecoilRefresher_UNSTABLE(MyContributions)
  const automationStateCounter = useSetRecoilState(automationDetailsRefresh)
  const refreshAutomation = () => automationStateCounter((num) => num + 1)

  const hasActionRequired = Boolean(
    automation?.AutomationApprovals?.filter(
      (approval) => approval.ApprovalState.Name === AutomationApprovalDisplayStates.ActionRequired,
    ).length,
  )
  const isScriptLibrary = Number(typeId) === AutomationUploadTypes.SCRIPT_LIBRARY
  const isStreamLiner = Number(typeId) === AutomationUploadTypes.STREAMLINER
  const isInReview = automation?.AutomationState?.Name === AutomationStateNames.IN_REVIEW
  const isOwner = automation?.AutomationAuthor?.UserEmail === user?.email
  const isApprover = automation?.CanApprove ?? false
  const hasReviews = Boolean(automation?.AutomationApprovals?.length)

  const showEditButton = isOwner && hasActionRequired
  const showAuthorButtons = !isScriptLibrary || !isInReview
  const showVerificationInformation = (isOwner || isApprover) && hasReviews
  const canViewAutomationDetails = useUserPermission(actions.VIEW_AUTOMATION_DETAILS)
  const canDownloadAutomation = useUserPermission(actions.DOWNLOAD_AUTOMATION_FILE)
  const canReviewAutomations = useUserPermission(actions.POST_APPROVER_COMMENT)
  const canDeactivateOwnAutomation = useUserPermission(actions.DEACTIVATE_OWN_AUTOMATION) && isOwner
  const canDeactivateOtherAutomation = useUserPermission(actions.DEACTIVATE_OTHER_AUTOMATION)
  const canReactivateOwnAutomation = useUserPermission(actions.REACTIVATE_OWN_AUTOMATION) && isOwner
  const canReactivateOtherAutomation = useUserPermission(actions.REACTIVATE_OTHER_AUTOMATION)
  const canDeleteOtherAutomation = useUserPermission(actions.DELETE_OTHER_AUTOMATION)
  const isExternalSource = isStreamLiner || isScriptLibrary

  useEffect(() => {
    if (!automationDetails || automationDetails.state !== 'hasValue' || !automationDetails.contents) {
      return
    }
    setAutomation(automationDetails.contents)
  }, [automationDetails])

  const hasBeenReviewed = useMemo(() => {
    if (!automation) return false
    const roles = Object.values(AppRolesEnum)
    const approverRoles = roles.filter(
      (role: string) => role !== AppRolesEnum.STANDARD_USER && role !== AppRolesEnum.SYSTEM_ADMIN,
    )

    const latestApprovals = approverRoles.map((role) => {
      return automation?.AutomationApprovals?.filter((approval) => approval.ApproverRole?.Name === role).sort(
        (a, b) =>
          (b.DateCreated ? new Date(b.DateCreated).getTime() : 0) -
          (a.DateCreated ? new Date(a.DateCreated).getTime() : 0),
      )[0]
    })

    const data = latestApprovals?.some((approval) => {
      if (approval?.ApprovalState.Name !== AutomationApprovalDisplayStates.AwaitingApproval) {
        return approval
      }
      return false
    })

    return data
  }, [automation])

  if (automationDetails.state === 'loading') {
    return (
      <Page>
        <LoadingScreen text='Loading Details' />
      </Page>
    )
  }

  const now = new Date()
  const showToast = () => {
    addToast({
      type: 'success',
      message: 'Link Copied To Clipboard',
      timeout: 2000,
      datetime: Number(dateToString(now, dateTimeFormat)),
    })
  }

  const handleCopyClick = async () => {
    const currentUrl = window.location.href
    await navigator.clipboard.writeText(currentUrl)
    showToast()
  }

  if (!canViewAutomationDetails) return <ErrorScreen title='401' description='Unauthorized Access' />

  if (automationDetails.state === 'hasValue' && !automationDetails.contents) {
    return <NotFound />
  }

  const handleViewClick = () => {
    if (isScriptLibrary) {
      window.open(`${SCRIPT_LIBRARY_VIEW_URL}/${automation?.Id}`, '_blank')
      return
    }

    window.open(automation?.AutomationUrl?.Url, '_blank')
  }

  const handleEditClick = () => {
    navigate(`/${AppRoutes.UPLOAD_AUTOMATION}/${automationId}`)
  }

  const handleGoBack = () => {
    if (history.length <= 2) {
      navigate(`/${AppRoutes.MANAGE}`)
      return
    }
    navigate(-1)
  }

  const handleAutomationStatusUpdate = async () => {
    if (!automation) return

    setShowConfirmationModal(false)
    setLoadingModalMessage('Saving changes...')
    setShowLoadingModal(true)

    const request = { automationId: automation.Id }
    let toastMessage = ''
    let response: IResponse<IResponseTypes> | null = null
    if (confirmationModalAction === actions.REACTIVATE_OTHER_AUTOMATION) {
      response = await reactivateAutomation(request)
      toastMessage = 'Automation has been reactivated.'
    } else if (confirmationModalAction === actions.DEACTIVATE_OTHER_AUTOMATION) {
      response = await deactivateAutomation(request)
      toastMessage = 'Automation has been deactivated. Please reach out to our support team to undo this.'
    } else if (confirmationModalAction === actions.DELETE_OTHER_AUTOMATION) {
      response = await deleteAutomation(request)
      toastMessage = 'Automation has been deleted.'
    }

    setShowLoadingModal(false)

    if (response?.success) {
      refreshManagePageDeactivated()
      refreshManagePageApprovals()
      refreshManagePageContributions()
      if (confirmationModalAction !== actions.DELETE_OTHER_AUTOMATION) refreshAutomation()

      addToast({
        type: 'success',
        message: toastMessage,
        timeout: 5000,
        datetime: Number(dateToString(now, dateTimeFormat)),
      })
      navigate(`/${AppRoutes.MANAGE}`)
    } else {
      addToast({
        type: 'error',
        message: 'Action failed',
        timeout: 5000,
        datetime: Number(dateToString(now, dateTimeFormat)),
      })
    }
  }

  const handleDownloadClick = async () => {
    if (!automation) return
    setLoadingModalMessage('Loading file...')
    setShowLoadingModal(true)
    await handleDownload(automation, currentUser)
    setShowLoadingModal(false)
  }

  return (
    <Page menu contentWrapper handleGoBack={handleGoBack}>
      {!automation ? (
        <div className={Style.viewerLoader}>
          <LoadingScreen size='extra small' text='Loading Details' />
        </div>
      ) : (
        <>
          <Grid row left xs={12}>
            <Grid xs={12} xl={8} cssClass={Style.headerTitle} center>
              <h1>{automation?.Title}</h1>
            </Grid>

            <Grid xs={12} xl={4} cssClass={Style.controls} center>
              <div>
                <Likes id={automation.Id} size='24px' />
              </div>
              {!isExternalSource && (
                <div>
                  <Icon type='copy' outlined onClick={handleCopyClick} cssClass={Style.copyIcon} />
                </div>
              )}
              {!isExternalSource &&
                automation.IsActive &&
                !hasBeenReviewed &&
                (canDeactivateOtherAutomation || canDeactivateOwnAutomation) && (
                  <div>
                    <Button
                      type='default'
                      size='medium'
                      label='Deactivate'
                      icon='cancel'
                      cssClass={Style.deactivateButton}
                      onClick={() => {
                        setConfirmationModalAction(actions.DEACTIVATE_OTHER_AUTOMATION)
                        setConfirmationModalTitle('Deactivate Automation?')
                        setConfirmationModalMessage(
                          'This action will deactivate the automation and will be hidden from Discovery page and automation approvals. You can reactivate this on "Deactivated" tab.\n\nAre you sure you want to continue?',
                        )
                        setShowConfirmationModal(true)
                      }}
                    />
                  </div>
                )}
              {!isExternalSource && !automation.IsActive && canDeleteOtherAutomation && (
                <div>
                  <Button
                    type='default'
                    size='medium'
                    label='Delete'
                    icon='delete_outline'
                    cssClass={Style.reactivateButton}
                    onClick={() => {
                      setConfirmationModalAction(actions.DELETE_OTHER_AUTOMATION)
                      setConfirmationModalTitle('Delete Automation?')
                      setConfirmationModalMessage(
                        <span>
                          This action will remove the automation<strong> permanently </strong>from the application. Are
                          you sure you want to continue?
                        </span>,
                      )
                      setShowConfirmationModal(true)
                    }}
                  />
                </div>
              )}
              {!isExternalSource &&
                !automation.IsActive &&
                (canReactivateOtherAutomation || canReactivateOwnAutomation) && (
                  <div>
                    <Button
                      type='default'
                      size='medium'
                      label='Reactivate'
                      icon='autorenew'
                      cssClass={Style.reactivateButton}
                      onClick={() => {
                        setConfirmationModalAction(actions.REACTIVATE_OTHER_AUTOMATION)
                        setConfirmationModalTitle('Reactivate Automation?')
                        setConfirmationModalMessage(
                          'This action will reactivate the automation and will reappear on Discovery page and automation approvals.\n\nAre you sure you want to continue?',
                        )
                        setShowConfirmationModal(true)
                      }}
                    />
                  </div>
                )}
              <div>
                <Button
                  type='default'
                  size='medium'
                  label='View'
                  icon='open_in_new'
                  cssClass={Style.viewButton}
                  onClick={handleViewClick}
                  disabled={!isScriptLibrary && !automation?.AutomationUrl?.Url}
                />
              </div>
              <div>
                <Button
                  type='default'
                  size='medium'
                  label='Download'
                  icon='file_download'
                  cssClass={Style.downloadButton}
                  onClick={handleDownloadClick}
                  disabled={!automation.AutomationFileUpload || !canDownloadAutomation}
                />
              </div>
            </Grid>
          </Grid>
          {automation && (
            <>
              <div className={Style.verificationInformationHeader}>Information</div>
              <AutomationInformation
                automationDetails={automation}
                isScriptLibrary={isScriptLibrary}
                isStreamliner={isStreamLiner}
              />
              <EstimatedAverageTimeSaved
                automationDetails={automation}
                isScriptLibrary={isScriptLibrary}
                isStreamliner={isStreamLiner}
              />
            </>
          )}

          {showVerificationInformation && automation?.AutomationApprovals && (
            <>
              <div className={Style.divider} />
              <VerificationInformation automationApprovals={automation?.AutomationApprovals} />
            </>
          )}

          {!isOwner &&
            isApprover &&
            !isScriptLibrary &&
            !isStreamLiner &&
            automation.IsActive &&
            canReviewAutomations &&
            automation?.AutomationState?.Name !== AutomationStateNames.APPROVED && (
              <CommentForm automation={automation} user={user} />
            )}

          {showAuthorButtons && (
            <>
              <div className={Style.divider} />
              <div className={Style.authorButtons}>
                {showEditButton && (
                  <Button
                    type='default'
                    size='medium'
                    label='Edit'
                    disabled={!hasActionRequired && isInReview}
                    cssClass={Style.edit}
                    onClick={handleEditClick}
                  />
                )}
                <Button type='default' size='medium' label='Close' cssClass={Style.close} onClick={handleGoBack} />
              </div>
            </>
          )}
          <ErrorModal />
        </>
      )}
      {showLoadingModal && <LoadingModal message={loadingModalMessage} />}
      <ConfirmModal
        title={confirmationModalTitle}
        message={confirmationModalMessage}
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onNo={() => setShowConfirmationModal(false)}
        onYes={handleAutomationStatusUpdate}
        cssClass={Style.confirmModal}
        titleIcon={confirmationModalAction === actions.DELETE_OTHER_AUTOMATION ? 'error_outline' : undefined}
        isCloseButton
      />
    </Page>
  )
}

export default AutomationViewer

import { IButtonProps, Modal, Dropdown, useToast } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil'
import Style from '../../../styles/common/modals/EditApproverRoleModal.module.sass'
import { AllApprovers, PermissionHistory, ShowEditApproverRoleModal } from '../../../stores/UserStore'
import { AppRolesEnum } from '../../../config/config'
import { AppRoleName, ApproverRoleName } from '../../../enums/AppRoles'
import { IGetApproverResponse, IPutEditRoleRequest } from '../../../models/api/IUserRequestModel'
import Tag from '../../Tag'
import { TagTypes } from '../../../enums/TagTypes'
import { editApproverRoleFormSchema } from '../../../validators/EditApproverRoleFormValidator'
import { editUserRole } from '../../../api/UserService'
import ConfirmModal from '../../ConfirmModal'

interface IEditApproverRoleModalProps {
  selectedUser: IGetApproverResponse | null
}

const EditApproverRoleModal: FC<IEditApproverRoleModalProps> = ({ selectedUser }) => {
  const [showEditApproverRoleModal, setShowEditApproverRoleModal] = useRecoilState(ShowEditApproverRoleModal)
  const resetAllApproverList = useRecoilRefresher_UNSTABLE(AllApprovers)
  const resetPermissionHistory = useRecoilRefresher_UNSTABLE(PermissionHistory)

  const [role, setRole] = useState('')
  const [user, setUser] = useState<IGetApproverResponse>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const emptyForm: IPutEditRoleRequest = { email: '', previousRole: '', newRole: '' }
  const [errors, setErrors] = useState<IPutEditRoleRequest>(emptyForm)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

  const { addToast } = useToast()

  useEffect(() => {
    if (selectedUser) {
      const currentRoleKey = Object.entries(AppRolesEnum).find(
        ([key]) => AppRolesEnum[key as keyof typeof AppRolesEnum] === selectedUser?.roles[0],
      )?.[0]
      setUser(selectedUser)
      setRole(AppRolesEnum[currentRoleKey as keyof typeof AppRolesEnum])
    }
  }, [selectedUser])

  const roleKeys = Object.keys(ApproverRoleName)
  const roleItems = roleKeys.map((key) => ({
    id: AppRolesEnum[key as keyof typeof AppRolesEnum],
    label: `${AppRoleName[AppRolesEnum[key as keyof typeof AppRolesEnum]]} Authoriser`,
  }))

  const handleClose = () => {
    setIsSubmitting(false)
    setShowConfirmationModal(false)
    setShowEditApproverRoleModal(false)
  }

  const handleSave = async () => {
    if (!user) return

    const payload = {
      email: user.email,
      previousRole: user.roles[0] ?? AppRolesEnum.STANDARD_USER,
      newRole: role,
    }

    const { error } = editApproverRoleFormSchema.validate(payload, {
      abortEarly: false,
    })

    if (error) {
      setErrors({
        email: error.details.find((err) => err.path[0] === 'email')?.message ?? '',
        previousRole: error.details.find((err) => err.path[0] === 'previousRole')?.message ?? '',
        newRole: error.details.find((err) => err.path[0] === 'newRole')?.message ?? '',
      })
      return
    }

    setErrors(emptyForm)
    setIsSubmitting(true)
    const result = await editUserRole(payload)

    if (!result.success) {
      addToast({
        type: 'error',
        message: 'Failed to assign role.',
        timeout: 4000,
        datetime: Date.now(),
      })
      return
    }

    resetAllApproverList()
    resetPermissionHistory()
    setIsSubmitting(false)
    handleClose()
    addToast({
      type: 'success',
      message: 'Authoriser’s role updated successfully.',
      timeout: 4000,
      datetime: Date.now(),
    })
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: isSubmitting ? 'Saving' : 'Save',
      type: 'secondary',
      onClick: () => {
        setShowEditApproverRoleModal(false)
        setShowConfirmationModal(true)
      },
      cssClass: Style.primaryButton,
      disabled: isSubmitting || role === selectedUser?.roles[0],
    },
  ]

  return (
    <>
      <Modal
        isShowing={showEditApproverRoleModal}
        isCloseButton
        onClose={handleClose}
        actions={ModalActions}
        shouldOverlayClose={false}
        size='medium'
        cssClass={Style.editApproverRoleModal}
        modalPadding={{
          top: '24px',
          right: '24px',
          left: '24px',
        }}
      >
        <>
          <div className={Style.iconRow}>
            <h2>Edit Authoriser Role?</h2>
          </div>
          <div className={Style.modalContent}>
            <div className={Style.userDetails}>
              <div className={Style.label}>User</div>
              <div className={Style.userEmail}>{`${selectedUser?.name} (${selectedUser?.email})`}</div>
            </div>
            <div className={Style.userDetails}>
              <div className={Style.label}>Capability</div>
              <div className={Style.userCapability}>
                {selectedUser?.capability && (
                  <Tag
                    label={selectedUser.capability}
                    type={TagTypes.CAPABILITY}
                    id={selectedUser.capability}
                    size='medium'
                  />
                )}
              </div>
            </div>
            <div className={Style.roleDrodownContainer}>
              <div className={Style.roleDrodownlabel}>Role *</div>
              <Dropdown
                items={roleItems}
                selectedItem={role}
                onSelectItem={(v) => setRole(v.toString())}
                required
                placeholder='Select a Role'
              />
              {errors.newRole && <p className={Style.errorMessage}>{errors.newRole}</p>}
            </div>
          </div>
        </>
      </Modal>
      <ConfirmModal
        title={'Update Role?'}
        message={
          <>
            This action will change the user’s permission on automations. Are you sure you want to change role for{' '}
            <span className={Style.userName}>{selectedUser?.name}</span>?
          </>
        }
        open={showConfirmationModal}
        onClose={() => {
          setShowEditApproverRoleModal(true)
          setShowConfirmationModal(false)
          setIsSubmitting(false)
        }}
        onNo={() => {
          setShowEditApproverRoleModal(true)
          setShowConfirmationModal(false)
          setIsSubmitting(false)
        }}
        onYes={() => handleSave()}
        loading={isSubmitting}
        isCloseButton
      />
    </>
  )
}

export default EditApproverRoleModal

import { Button, FormInput, useToast } from '@aurecon-creative-technologies/styleguide'
import { FC, useMemo, useState } from 'react'
import Style from '../../styles/AutomationViewer.module.sass'
import ApproverModal from './ApproverModal'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useSetRecoilState } from 'recoil'
import { ShowApproverModal, automationDetailsRefresh } from '../../stores/AutomationViewerStore'
import { AppRoutes } from '../../enums/AppRoutes'
import { ApproverModalActionTypes } from '../../enums/AutomationViewer'
import { IGetAutomationResponse } from '../../models/api/IAutomationRequest'
import { updateApprovals } from '../../api/AutomationService'
import { AutomationApprovalDisplayStates } from '../../enums/AutomationApprovalStates'
import {
  ActiveManageTabState,
  ApprovedAutomationsAndScripts,
  MyContributions,
  PendingApprovalList,
} from '../../stores/ManageAutomationStore'
import { TabsEnum } from '../../enums/Manage'
import ActionNotRequiredModal from './ActionNotRequiredModal'
import { User } from '@auth0/auth0-react'
import { commentFormFields, commentFormSchema } from '../../validators/CommentFormValidator'
import { formatValidationResult } from '../../helpers/commonFunctions'
import classNames from 'classnames'
import { getUserRoles } from '../../helpers/appRoles'
import { AppRolesEnum } from '../../config/config'
import LoadingModal from '../LoadingModal'
import { useUserPermission } from '../../hooks/useUserPermission'
import { actions } from '../../helpers/userPermission'

interface CommentFormProps {
  automation: IGetAutomationResponse
  user?: User
}

const handleCancel = () => {
  location.hash = `#/${AppRoutes.MANAGE}`
}

const CommentForm: FC<CommentFormProps> = (props: CommentFormProps) => {
  const { automation, user } = props
  const toast = useToast()
  const [comment, setComment] = useState<string>('')
  const [showApproverModal, setShowApproverModal] = useRecoilState(ShowApproverModal)
  const refreshManagePageApprovals = useRecoilRefresher_UNSTABLE(PendingApprovalList)
  const refreshManagePageContributions = useRecoilRefresher_UNSTABLE(MyContributions)
  const refreshDiscoverPageAutomations = useRecoilRefresher_UNSTABLE(ApprovedAutomationsAndScripts)
  const automationStateCounter = useSetRecoilState(automationDetailsRefresh)
  const refreshAutomationDetails = () => automationStateCounter((num) => num + 1)
  const setActiveManageTab = useSetRecoilState(ActiveManageTabState)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)

  const hasRejected = automation.HasRejected
  const hasApprovalFromSameRole = automation.HasApprovalFromSameRole

  const hasFormErrors = Object.keys(formErrors).length !== 0

  const isActionDisabled = Boolean(hasApprovalFromSameRole) || Boolean(hasRejected) || !comment.trim() || hasFormErrors
  const isDigitalApprover = useMemo(() => {
    const roles = getUserRoles(user)
    return roles.includes(AppRolesEnum.DIGITAL)
  }, [user])

  const canRejectAutomation = useUserPermission(actions.AUTOMATION_REVIEW_REJECT)
  const canApproveAutomation = useUserPermission(actions.AUTOMATION_REVIEW_APPROVE)
  const canRequireActionAutomation = useUserPermission(actions.AUTOMATION_REVIEW_REQUIRE_ACTION)
  const canAcceptForTrackingAutomation = useUserPermission(actions.AUTOMATION_REVIEW_ACCEPT_FOR_TRACKING)
  const canPostComment = useUserPermission(actions.POST_APPROVER_COMMENT)

  const commentClasses = classNames({
    [Style.commentTextArea]: true,
    [Style.hasError]: !!formErrors[commentFormFields.comment],
  })

  const onCommentValueChange = (value: string) => {
    setComment(value)
    const result = commentFormSchema.validate({ comment: value })
    if (!result.error) {
      setFormErrors({})
      return
    }

    const formattedErrors = formatValidationResult(result.error)
    setFormErrors(formattedErrors ?? {})
  }

  const handleYesClick = async () => {
    let action = null

    switch (showApproverModal.type) {
      case ApproverModalActionTypes.APPROVE:
        action = AutomationApprovalDisplayStates.Approved
        break

      case ApproverModalActionTypes.REJECT:
        action = AutomationApprovalDisplayStates.Rejected
        break

      case ApproverModalActionTypes.REQUIRE_ACTION:
        action = AutomationApprovalDisplayStates.ActionRequired
        break

      case ApproverModalActionTypes.ACCEPT_FOR_TRACKING:
        action = AutomationApprovalDisplayStates.AcceptedForTracking
        break

      default:
        break
    }
    setShowApproverModal({ show: false, type: ApproverModalActionTypes.CLOSE })

    if (!action) return
    setShowLoadingModal(true)
    const response = await updateApprovals({
      automationId: automation.Id,
      approvalStateName: action,
      comment: comment,
    })
    setShowLoadingModal(false)
    if (response?.success) {
      setComment('')

      refreshManagePageApprovals()
      refreshManagePageContributions()
      if (automation.AutomationApprovals?.length === 2) refreshDiscoverPageAutomations()
      refreshAutomationDetails()
      setActiveManageTab(TabsEnum.APPROVALS)
      location.hash = `#/${AppRoutes.MANAGE}`
    } else {
      toast.addToast({
        message: `Error: ${response?.message}`,
        type: 'error',
        timeout: 3000,
      })
    }
  }

  return (
    <>
      <div className={Style.divider} />
      <div className={Style.comments}>
        <FormInput
          type='multiline'
          required
          label='Comments'
          placeholder='Enter comment here...'
          cssClass={commentClasses}
          value={comment}
          disabled={Boolean(hasApprovalFromSameRole) || Boolean(hasRejected) || !canPostComment}
          onChange={onCommentValueChange}
          multilineLimit={1000}
          error={formErrors[commentFormFields.comment]}
        />

        <div className={Style.commentButtons}>
          <Button type='default' size='medium' label='Cancel' cssClass={Style.cancel} onClick={handleCancel} />
          {isDigitalApprover && (
            <Button
              type='default'
              size='medium'
              label='Accept For Tracking'
              icon='task'
              cssClass={Style.acceptForTracking}
              onClick={() => setShowApproverModal({ show: true, type: ApproverModalActionTypes.ACCEPT_FOR_TRACKING })}
              disabled={isActionDisabled || !canAcceptForTrackingAutomation}
            />
          )}
          <Button
            type='default'
            size='medium'
            label='Reject'
            icon='cancel'
            cssClass={Style.reject}
            onClick={() => setShowApproverModal({ show: true, type: ApproverModalActionTypes.REJECT })}
            disabled={isActionDisabled || !canRejectAutomation}
          />
          <Button
            type='default'
            size='medium'
            label='Require Action'
            icon='error'
            cssClass={Style.actionRequired}
            onClick={() => setShowApproverModal({ show: true, type: ApproverModalActionTypes.REQUIRE_ACTION })}
            disabled={isActionDisabled || !canRequireActionAutomation}
          />
          <Button
            type='default'
            size='medium'
            label='Approve'
            icon='check_circle'
            cssClass={Style.approve}
            onClick={() => setShowApproverModal({ show: true, type: ApproverModalActionTypes.APPROVE })}
            disabled={isActionDisabled || !canApproveAutomation}
          />
        </div>
      </div>
      <ApproverModal onYes={handleYesClick} />
      {hasRejected && <ActionNotRequiredModal show={Boolean(hasRejected.ApprovedBy?.UserEmail === user?.email)} />}
      {showLoadingModal && <LoadingModal message='Saving changes...' />}
    </>
  )
}

export default CommentForm

export const SUPPORT_EMAIL = 'node-react-template.support@aurecongroup.com'
export const ABOUT_URL = 'https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/Automation-Centre.aspx'
export const SUPPORT_HUB_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/36'
export const SERVICE_NOW_URL = 'https://aureconprod.service-now.com/bss/ask_for_help.do'
export const FAQ_URL = 'https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/Automation-Centre-FAQs.aspx'
export const REQUEST_ACCESS_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/24'
export const PROPELLER_URL = 'https://aurecongroup.sharepoint.com/sites/Propeller'
export const RELEASE_NOTES_URL =
  'https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/Automation-Centre-Releases.aspx'
export const LEARN_URL = 'https://aurecongroup.sharepoint.com/sites/Hive-OurCompany/SitePages/Universal-Automation.aspx'
export const AUTOMATION_NETWORK_URL =
  'https://aurecongroup.sharepoint.com/sites/Hive-OurCompany/SitePages/Universal-Automation.aspx#our-networks'
export const SCRIPT_LIBRARY_URL = 'https://script-library.aurecongroup.digital/create'
export const SCRIPT_LIBRARY_VIEW_URL = 'https://script-library.aurecongroup.digital/scripts'
export const MAX_FILE_NAME = 80
export const UI_WIDTH_COLLAPSE = 770
export const MAX_TAGS_ON_CARDS = 2
export const MAX_AUTOMATION_FILE_SIZE = 26214400
export const SEARCH_PAGE_SIZE = 12
export const PAGE_SIZE = 10
export const INSIGHTS_MAX_PAGE_SIZE = 10
export const REQUIRED_APPROVALS = 3

export const BLOB_MACROS_CONTAINER = 'macros'
export const BLOB_OFFICE_SCRIPTS_CONTAINER = 'officescripts'
export const BLOB_THUMBNAILS_CONTAINER = 'thumbnails'

export const LAUNCH_DATE = '2024-06-16'

const config = {
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
  AUTH0_LOGOUT_RETURN: window.location.origin,
  AUTH0_ROLES: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  AUTH0_GLOBAL_ADMIN_ROLE: 'node-react-template Global Admin',
  MOBLIE_VIEW_MAX_WIDTH: 768,
}

export const AppRolesEnum = {
  SYSTEM_ADMIN: process.env.REACT_APP_SYSTEM_ADMIN_ROLE_NAME ?? 'AC_SystemAdmin',
  TECHNICAL: process.env.REACT_APP_TECHNICAL_APPROVER_ROLE_NAME ?? 'AC_Technical_Approver',
  IT: process.env.REACT_APP_IT_APPROVER_ROLE_NAME ?? 'AC_IT_Approver',
  DIGITAL: process.env.REACT_APP_DIGITAL_APPROVER_ROLE_NAME ?? 'AC_Digital_Approver',
  STANDARD_USER: 'AC_Standard_User',
}

export const StatusIconColours = {
  Rejected: '#DA3B20',
  ActionRequired: '#14A4DE',
  AwaitingApproval: '#F6D510',
  Approved: '#00BD39',
  Draft: '#E0E0E0',
  Deactivated: '#8E9C9C',
  AcceptedForTracking: '#8505D4',
}

export const BarGraphSettings = {
  BarSize: 88,
  BarGraphColours: {
    AutomationCentre: '#9EE8B1',
    ScriptLibrary: '#A7D4D4',
  },
  LabelListFill: '#373A36',
  LabelListOffset: 20,
  LegendHeight: 44,
  YAxisPadding: { top: 50 },
  AxisWidth: 1,
}

export const LINE_CHART_COLOUR = '#3F51B5'

export const TOUR_LINK =
  'https://aurecongroup.sharepoint.com/sites/Hive-Digital/_layouts/15/stream.aspx?id=%2Fsites%2FHive%2DDigital%2FVideos%2FDIGITAL%20%2D%20Automation%20Centre%20Tour%2FDIGITAL%20%2D%20Automation%20Centre%20Tour%2Emp4&referrer=StreamWebApp%2EWeb&referrerScenario=AddressBarCopied%2Eview%2E4b647880%2D55a3%2D4121%2D9012%2Dbeeca8344282'

export default config

import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Icon } from '@aurecon-creative-technologies/styleguide'

import Page from '../components/Page'
import config from '../config/config'

import PageStyle from '../styles/Profile.module.sass'
import { appInsights } from '../api/AppInsights'
import { getUserRoles } from '../helpers/appRoles'
import { actions } from '../helpers/userPermission'
import { useUserPermission } from '../hooks/useUserPermission'
import ErrorScreen from './ErrorScreen'

const Profile: FC = () => {
  const { user, logout } = useAuth0()

  const canViewProfilePage = useUserPermission(actions.VIEW_PROFILE_PAGE)

  if (!canViewProfilePage) return <ErrorScreen title='401' description='Unauthorized Access' />

  if (appInsights) appInsights.trackPageView({ name: 'Profile' })

  const handleLogout = () => {
    const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
    logout({ returnTo })
  }
  const userRoles = getUserRoles(user)

  return (
    <Page menu>
      <div className={PageStyle.layoutContainer}>
        <p className={PageStyle.profileIcon}>
          <Icon type='account_circle' outlined size='40px' />
        </p>
        <p className={PageStyle.email}>{user?.email}</p>
        {!userRoles?.length ? (
          <p className={PageStyle.role}>User</p>
        ) : (
          userRoles.map((r) => (
            <p key={r} className={PageStyle.role}>
              {r}
            </p>
          ))
        )}
        <Button type='primary' label='Logout' width='300px' onClick={handleLogout} />
      </div>
    </Page>
  )
}

export default Profile

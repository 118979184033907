import { IButtonProps, Modal, Dropdown, useToast } from '@aurecon-creative-technologies/styleguide'
import { FC, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil'
import Style from '../../../styles/common/modals/AddNewApproverModal.module.sass'
import { AllApprovers, PermissionHistory, ShowAddNewApproverModal } from '../../../stores/UserStore'
import ApproverComboBox from '../../ApproverComboBox'
import { AppRolesEnum } from '../../../config/config'
import { AppRoleName, ApproverRoleName } from '../../../enums/AppRoles'
import { assignRoleToUser } from '../../../api/UserService'
import { addNewApproverFormSchema } from '../../../validators/AddNewApproverFormValidator'

const AddNewApproverModal: FC = () => {
  const [showAddNewApproverModal, setShowAddNewApproverModal] = useRecoilState(ShowAddNewApproverModal)
  const resetAllApproverList = useRecoilRefresher_UNSTABLE(AllApprovers)
  const resetPermissionHistory = useRecoilRefresher_UNSTABLE(PermissionHistory)
  const [role, setRole] = useState('')
  const [user, setUser] = useState<string | number>()
  const [errors, setErrors] = useState<{ email: string; role: string }>({ email: '', role: '' })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { addToast } = useToast()

  const roleKeys = Object.keys(ApproverRoleName)
  const roleItems = roleKeys.map((key) => ({
    id: AppRolesEnum[key as keyof typeof AppRolesEnum],
    label: `${AppRoleName[AppRolesEnum[key as keyof typeof AppRolesEnum]]} Authoriser`,
  }))

  const handleClose = () => {
    setUser('')
    setRole('')
    setIsSubmitting(false)
    setShowAddNewApproverModal(false)
  }

  const handleSave = async () => {
    const { error } = addNewApproverFormSchema.validate(
      { email: user, role },
      {
        abortEarly: false,
      },
    )

    if (error) {
      setErrors({
        email: error.details.find((err) => err.path[0] === 'email')?.message || '',
        role: error.details.find((err) => err.path[0] === 'role')?.message || '',
      })
      return
    }

    setErrors({ email: '', role: '' })
    setIsSubmitting(true)
    const result = await assignRoleToUser({
      email: user?.toString() ?? '',
      role: role,
    })

    if (!result.success) {
      addToast({
        type: 'error',
        message: 'Failed to assign role.',
        timeout: 4000,
        datetime: Date.now(),
      })
      return
    }

    resetAllApproverList()
    resetPermissionHistory()
    handleClose()
    addToast({
      type: 'success',
      message: 'Authoriser has been added.',
      timeout: 4000,
      datetime: Date.now(),
    })
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: isSubmitting ? 'Saving' : 'Save',
      type: 'secondary',
      onClick: handleSave,
      cssClass: Style.primaryButton,
      disabled: isSubmitting,
    },
  ]

  return (
    <Modal
      isShowing={showAddNewApproverModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='medium'
      cssClass={Style.addNewApproverModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <h2>Add Authoriser?</h2>
        </div>
        <div className={Style.modalContent}>
          <div className={Style.approverComboBoxContainer}>
            <ApproverComboBox
              label='User'
              placeholder='Search for User'
              required
              onSelectedItemsChange={(data) => setUser(data.id)}
            />
            {errors.email && <p className={Style.errorMessage}>{errors.email}</p>}
          </div>
          <div className={Style.roleDrodownContainer}>
            <div className={Style.roleDrodownlabel}>Role *</div>
            <Dropdown
              items={roleItems}
              selectedItem={role}
              onSelectItem={(v) => setRole(v.toString())}
              required
              placeholder='Select a Role'
            />
            {errors.role && <p className={Style.errorMessage}>{errors.role}</p>}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default AddNewApproverModal

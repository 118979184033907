import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Page from '../components/Page'
import PageStyle from '../styles/ApproverManagement.module.sass'
import {
  Container,
  Button,
  FormInput,
  Table,
  TableRow,
  TableCell,
  Icon,
  IHeader,
  Tooltip,
} from '@aurecon-creative-technologies/styleguide'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import {
  AllApprovers,
  ShowAddNewApproverModal,
  ShowPermissionHistoryModal,
  ShowEditApproverRoleModal,
  ShowRemoveApproverModal,
} from '../stores/UserStore'
import LoadingScreen from '../components/LoadingScreen'
import { IGetApproverResponse } from '../models/api/IUserRequestModel'
import { AppRolesEnum, PAGE_SIZE } from '../config/config'
import Tag from '../components/Tag'
import { TagTypes } from '../enums/TagTypes'
import { useUserPermission } from '../hooks/useUserPermission'
import { actions } from '../helpers/userPermission'
import AddNewApproverModal from '../components/common/modals/AddNewApproverModal'
import PermissionHistoryModal from '../components/common/modals/PermissionHistoryModal'
import CustomPagination from '../components/common/CustomPagination'
import ErrorScreen from './ErrorScreen'
import EditApproverRoleModal from '../components/common/modals/EditApproverRoleModal'
import RemoveApproverModal from '../components/common/modals/RemoveApproverModal'
import classNames from 'classnames'

type sortFields = 'name' | 'email' | 'systemAdmin' | 'technical' | 'it' | 'digital' | 'capability'

const ApproverManagement: FC = () => {
  const allApprovers = useRecoilValueLoadable(AllApprovers)
  const [showAddNewApproverModal, setShowAddNewApproverModal] = useRecoilState(ShowAddNewApproverModal)
  const [showEditApproverRoleModal, setShowEditApproverRoleModal] = useRecoilState(ShowEditApproverRoleModal)
  const [showRemoveApproverModal, setShowRemoveApproverModal] = useRecoilState(ShowRemoveApproverModal)
  const [showPermissionHistoryModal, setShowPermissionHistoryModal] = useRecoilState(ShowPermissionHistoryModal)
  const [approverList, setApproverList] = useState<IGetApproverResponse[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [nameSort, setNameSort] = useState('asc')
  const [emailSort, setEmailSort] = useState('none')
  const [systemAdminSort, setSystemAdminSort] = useState('none')
  const [technicalSort, setTechnicalSort] = useState('none')
  const [iTSort, setITSort] = useState('none')
  const [digitalSort, setDigitalSort] = useState('none')
  const [capabilitySort, setCapabilitySort] = useState('none')
  const [searchText, setSearchText] = useState('')

  const [showContextMenu, setShowContextMenu] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState('')
  const [selectedUser, setSelectedUser] = useState<IGetApproverResponse | null>(null)

  const canViewUserList = useUserPermission(actions.VIEW_USER_LIST)
  const canAddNewApprover = useUserPermission(actions.ADD_NEW_APPROVER)
  const canViewPermissionHistory = useUserPermission(actions.VIEW_PERMISSION_HISTORY)
  const canChangeApproverRole = useUserPermission(actions.CHANGE_APPROVER_ROLE)
  const canRemoveApprover = useUserPermission(actions.REMOVE_APPROVER_ROLE)

  const pageCount = allApprovers.contents.length / PAGE_SIZE
  const maxPages = Math.ceil(pageCount > 0 ? pageCount : 1)

  useEffect(() => {
    if (!allApprovers || allApprovers.state !== 'hasValue' || !allApprovers.contents) {
      return
    }
    const data = allApprovers.contents as IGetApproverResponse[]
    setApproverList(data)
  }, [allApprovers, currentPage])

  const tableHeaders = [
    {
      label: 'User Name',
      sort: nameSort,
      onSort: (sortString: string) => handleSort(sortString, 'name'),
    },
    {
      label: 'System Admin',
      sort: systemAdminSort,
      onSort: (sortString: string) => handleSort(sortString, 'systemAdmin'),
      align: 'center',
    },
    {
      label: 'Technical Authoriser',
      sort: technicalSort,
      onSort: (sortString: string) => handleSort(sortString, 'technical'),
      align: 'center',
    },
    {
      label: 'IT Authoriser',
      sort: iTSort,
      onSort: (sortString: string) => handleSort(sortString, 'it'),
      align: 'center',
    },
    {
      label: 'Digital Authoriser',
      sort: digitalSort,
      onSort: (sortString: string) => handleSort(sortString, 'digital'),
      align: 'center',
    },
    {
      label: 'Capability',
      sort: capabilitySort,
      onSort: (sortString: string) => handleSort(sortString, 'capability'),
    },
  ] as IHeader[]

  const approversSorted = useMemo(() => {
    const approverSearch = !searchText
      ? [...approverList]
      : [...approverList].filter((approver) => {
          const searchTextLowercase = searchText.toLowerCase()
          return (
            approver.name.toLowerCase().includes(searchTextLowercase) ||
            approver.email.toLowerCase().includes(searchTextLowercase)
          )
        })

    if (nameSort !== 'none')
      return approverSearch.sort((a, b) => {
        const sort = nameSort === 'asc' ? 1 : -1
        return a.name?.localeCompare(b.name) * sort
      })

    if (emailSort !== 'none')
      return approverSearch.sort((a, b) => {
        const sort = emailSort === 'asc' ? 1 : -1
        return a.email?.localeCompare(b.email) * sort
      })

    if (systemAdminSort !== 'none')
      return approverSearch.sort((a, b) => {
        const systemAdminFirst = a.isSystemAdmin ? 0 : 1
        const systemAdminSecond = b.isSystemAdmin ? 0 : 1

        const sort = systemAdminSort === 'asc' ? 1 : -1
        return systemAdminFirst > systemAdminSecond ? sort : -sort
      })

    if (technicalSort !== 'none')
      return approverSearch.sort((a, b) => {
        const targetA = a.roles.includes(AppRolesEnum.TECHNICAL) ? 0 : 1
        const targetB = b.roles.includes(AppRolesEnum.TECHNICAL) ? 0 : 1

        const sort = technicalSort === 'asc' ? 1 : -1
        return targetA > targetB ? sort : -sort
      })

    if (iTSort !== 'none')
      return approverSearch.sort((a, b) => {
        const targetA = a.roles.includes(AppRolesEnum.IT) ? 0 : 1
        const targetB = b.roles.includes(AppRolesEnum.IT) ? 0 : 1

        const sort = iTSort === 'asc' ? 1 : -1
        return targetA > targetB ? sort : -sort
      })

    if (digitalSort !== 'none')
      return approverSearch.sort((a, b) => {
        const targetA = a.roles.includes(AppRolesEnum.DIGITAL) ? 0 : 1
        const targetB = b.roles.includes(AppRolesEnum.DIGITAL) ? 0 : 1

        const sort = digitalSort === 'asc' ? 1 : -1
        return targetA > targetB ? sort : -sort
      })

    if (capabilitySort !== 'none')
      return approverSearch.sort((a, b) => {
        const sort = capabilitySort === 'asc' ? 1 : -1
        return a.capability?.localeCompare(b.capability) * sort
      })

    return approverSearch
  }, [
    searchText,
    approverList,
    nameSort,
    emailSort,
    systemAdminSort,
    technicalSort,
    iTSort,
    digitalSort,
    capabilitySort,
  ])

  const handleSort = useCallback(
    (sortString: string, type: sortFields) => {
      setNameSort(type === 'name' ? sortString : 'none')
      setEmailSort(type === 'email' ? sortString : 'none')
      setSystemAdminSort(type === 'systemAdmin' ? sortString : 'none')
      setTechnicalSort(type === 'technical' ? sortString : 'none')
      setITSort(type === 'it' ? sortString : 'none')
      setDigitalSort(type === 'digital' ? sortString : 'none')
      setCapabilitySort(type === 'capability' ? sortString : 'none')
    },
    [setNameSort, setEmailSort, setSystemAdminSort, setTechnicalSort, setITSort, setDigitalSort, setCapabilitySort],
  )

  const handleOpenContextMenu = useCallback(
    (rowId: string) => {
      if (selectedRowId === rowId) {
        setShowContextMenu(!showContextMenu)
      } else {
        setSelectedRowId(rowId)
        setShowContextMenu(true)
      }
    },
    [selectedRowId, showContextMenu],
  )

  const handleEdit = (user: IGetApproverResponse) => {
    setSelectedUser(user)
    setShowContextMenu(!showContextMenu)
    setShowEditApproverRoleModal(!showEditApproverRoleModal)
  }

  const handleDelete = (user: IGetApproverResponse) => {
    if (user.isSystemAdmin && user.roles.length === 0) return

    setSelectedUser(user)
    setShowContextMenu(!showContextMenu)
    setShowRemoveApproverModal(!showRemoveApproverModal)
  }

  if (!canViewUserList) return <ErrorScreen title='401' description='Unauthorized Access' />

  return (
    <Page menu manage>
      <Container cssClass={PageStyle.layoutContainer} fluid>
        <header className={PageStyle.header}>
          <h2 className={PageStyle.pageTitlte}>Authoriser Management</h2>
        </header>
        <div className={PageStyle.searchContainer}>
          <div className={PageStyle.addUserBtnContainer}>
            {canAddNewApprover && (
              <Button
                type='icon-round'
                size='medium'
                icon='add'
                cssClass={PageStyle.addUserBtn}
                onClick={() => setShowAddNewApproverModal(!showAddNewApproverModal)}
              />
            )}
          </div>
          <FormInput
            type='text'
            placeholder='Search User here...'
            value={searchText}
            onChange={setSearchText}
            icon='search'
            cssClass={PageStyle.searchFormInput}
          />
          {canViewPermissionHistory && (
            <Button
              type='secondary'
              size='large'
              label='Permission History'
              title='Permission History'
              cssClass={PageStyle.permissionHistoryBtn}
              onClick={() => setShowPermissionHistoryModal(!showPermissionHistoryModal)}
            />
          )}
        </div>
        <div className={PageStyle.tableContainer}>
          {allApprovers.state === 'loading' ? (
            <LoadingScreen size='medium' text='Loading...' />
          ) : (
            <Table cssClass={PageStyle.usersTable} headers={tableHeaders}>
              {approversSorted.length > 0 ? (
                approversSorted
                  .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
                  .map((approver: IGetApproverResponse) => {
                    const removeApproverClasses = classNames({
                      [PageStyle.contextMenu]: true,
                      [PageStyle.contextMenuDisabled]: approver.isSystemAdmin && approver.roles.length === 0,
                    })
                    return (
                      <TableRow key={approver.id} rowClass={PageStyle.tableRow}>
                        <TableCell cellClass={PageStyle.userName}>
                          <Tooltip
                            show={`${approver.name} (${approver.email})`}
                            padding={32}
                            cssClass='tooltip-theme-dark'
                            defaultUp
                          >
                            {approver.name}
                          </Tooltip>
                        </TableCell>
                        <TableCell cellClass={PageStyle.systemAdmin} align='center'>
                          {approver.isSystemAdmin ? <Icon type='check' outlined /> : ''}
                        </TableCell>
                        <TableCell cellClass={PageStyle.userRole} align='center'>
                          {approver.roles?.includes(AppRolesEnum.TECHNICAL) ? <Icon type='check' outlined /> : ''}
                        </TableCell>
                        <TableCell cellClass={PageStyle.userRole} align='center'>
                          {approver.roles?.includes(AppRolesEnum.IT) ? <Icon type='check' outlined /> : ''}
                        </TableCell>
                        <TableCell cellClass={PageStyle.userRole} align='center'>
                          {approver.roles?.includes(AppRolesEnum.DIGITAL) ? <Icon type='check' outlined /> : ''}
                        </TableCell>
                        <TableCell cellClass={PageStyle.userRole}>
                          {approver.capability && (
                            <Tag
                              label={approver.capability}
                              type={TagTypes.CAPABILITY}
                              id={approver.capability}
                              size='small'
                            />
                          )}
                        </TableCell>
                        {canChangeApproverRole && (
                          <TableCell>
                            <div className={PageStyle.action}>
                              <Button
                                type='icon-round'
                                size='extra small'
                                icon='more_horiz'
                                cssClass={PageStyle.actionBtn}
                                onClick={() => handleOpenContextMenu(approver.id)}
                              />
                              {showContextMenu && selectedRowId === approver.id && (
                                <div className={PageStyle.contextMenuContainer}>
                                  {canChangeApproverRole && (
                                    <div
                                      className={PageStyle.contextMenu}
                                      role='none'
                                      onClick={() => handleEdit(approver)}
                                    >
                                      Edit
                                    </div>
                                  )}
                                  {canRemoveApprover && (
                                    <div
                                      className={removeApproverClasses}
                                      role='none'
                                      onClick={() => handleDelete(approver)}
                                    >
                                      Remove
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })
              ) : (
                <TableRow rowClass={PageStyle.tableRow}>
                  <TableCell colSpan={8} align='right' style={{ textAlign: 'center', padding: '30px' }}>
                    No available data
                  </TableCell>
                </TableRow>
              )}
            </Table>
          )}
        </div>
        <CustomPagination currentPage={currentPage} setCurrentPage={setCurrentPage} maxPages={maxPages} />
      </Container>
      <AddNewApproverModal />
      <EditApproverRoleModal selectedUser={selectedUser} />
      <RemoveApproverModal selectedUser={selectedUser} />
      <PermissionHistoryModal />
    </Page>
  )
}

export default ApproverManagement

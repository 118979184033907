import { FC, useMemo } from 'react'
import classNames from 'classnames'
import Style from '../styles/Menu.module.sass'
import { useLocation } from 'react-router-dom'
import { FormInput, Icon, Loader, Pill } from '@aurecon-creative-technologies/styleguide'
import AutomationCenterLogo from '../assets/automation_center_logo.svg'
import AutomationCenterLogoSmall from '../assets/automation_center_logo_small.svg'
import config, {
  AUTOMATION_NETWORK_URL,
  FAQ_URL,
  LEARN_URL,
  PROPELLER_URL,
  RELEASE_NOTES_URL,
  UI_WIDTH_COLLAPSE,
} from '../config/config'
import { useAuth0 } from '@auth0/auth0-react'
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil'
import { AppVersion, SearchValue } from '../stores/AppStore'
import { useMediaQuery } from 'react-responsive'
import { AppRoutes } from '../enums/AppRoutes'
import { FetchPendingApprovalList } from '../stores/ManageAutomationStore'
import { isApproverUser } from '../helpers/appRoles'
import { actions } from '../helpers/userPermission'
import { useUserPermission } from '../hooks/useUserPermission'

const Menu: FC = () => {
  const loc = useLocation()
  const { logout } = useAuth0()
  const version = useRecoilValue(AppVersion)
  const [searchValue, setSearchValue] = useRecoilState(SearchValue)
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const approvalsLoadable = useRecoilValueLoadable(FetchPendingApprovalList)

  const { user } = useAuth0()
  const isApprover = isApproverUser(user)

  const canSearchAutomation = useUserPermission(actions.SEARCH_AUTOMATION)
  const canViewLearnPage = useUserPermission(actions.VIEW_LEARN_PAGE)
  const canViewAutomationNetwork = useUserPermission(actions.VIEW_AUTOMATION_NETWORK_PAGE)
  const canViewPropellerPage = useUserPermission(actions.VIEW_PROFILE_PAGE)
  const canViewReleaseNotesPage = useUserPermission(actions.VIEW_RELEASE_NOTE_PAGE)
  const canViewFAQPage = useUserPermission(actions.VIEW_FAQ_PAGE)
  const canViewProfilePage = useUserPermission(actions.VIEW_PROFILE_PAGE)
  const canUploadAutomation = useUserPermission(actions.UPLOAD_AUTOMATION)
  const canViewAllAutomationApprovals = useUserPermission(actions.VIEW_ALL_AUTOMATION_APPROVALS)
  const canViewAutomationApprovals = useUserPermission(actions.VIEW_APPROVAL_LIST)
  const canViewContributionList = useUserPermission(actions.VIEW_CONTRIBUTION_LIST)
  const canViewUserList = useUserPermission(actions.VIEW_USER_LIST)
  const canViewApplicationInsights = useUserPermission(actions.VIEW_APPLICATION_INSIGHTS)

  const approvals = useMemo(() => {
    if (approvalsLoadable.state !== 'hasValue') return null
    const approvals = approvalsLoadable.contents
    const count = approvals?.filter((a) => !a.HasApprovalFromSameRole).length ?? 0
    return count > 99 ? '99+' : count
  }, [approvalsLoadable.contents, approvalsLoadable.state])

  const menuClasses = classNames({
    [Style.menu]: true,
    [Style.collapsed]: !isDesktop,
  })

  const discoverClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.DISCOVER}`),
  })

  const manageClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.MANAGE}`),
  })

  const uploadClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.UPLOAD_AUTOMATION}`),
  })

  const profileClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.PROFILE}`),
  })

  const authoriserManagementClasses = classNames({
    [Style.menuItem]: true,
    [Style.active]: loc.pathname.includes(`/${AppRoutes.APPROVER_MANAGEMENT}`),
  })

  const staticMenuClasses = classNames({
    [Style.menuItem]: true,
  })

  const handleDiscover = () => {
    location.hash = `#/${AppRoutes.DISCOVER}`
    setSearchValue('')
  }

  const handleManage = () => {
    location.hash = `#/${AppRoutes.MANAGE}`
    setSearchValue('')
  }

  const handleUpload = () => {
    location.hash = `#/${AppRoutes.UPLOAD_AUTOMATION}`
    setSearchValue('')
  }

  const handleApplicationInsights = () => {
    location.hash = `#/${AppRoutes.APPLICATION_INSIGHTS}`
    setSearchValue('')
  }

  const handleProfile = () => {
    location.hash = `#/${AppRoutes.PROFILE}`
    setSearchValue('')
  }

  const handleAuthoriserManagement = () => {
    location.hash = `#/${AppRoutes.APPROVER_MANAGEMENT}`
    setSearchValue('')
  }

  const handleStaticMenuClick = (link: string) => {
    window.open(link, '_blank')
    setSearchValue('')
  }

  const handleLogout = () => {
    const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
    logout({ returnTo })
  }

  const handleSearch = (event: string) => {
    if (event === 'Enter') {
      location.hash = `#/${AppRoutes.SEARCH}/${searchValue}`
    }
  }

  const handleChange = (text: string) => {
    setSearchValue(text)
    if (!text) location.hash = `#/${AppRoutes.SEARCH}`
  }

  const handleReturnToHome = () => {
    location.hash = `#/`
    setSearchValue('')
  }

  return (
    <div className={menuClasses}>
      <button className={Style.logoButton} onClick={handleReturnToHome}>
        <img
          src={isDesktop ? AutomationCenterLogo : AutomationCenterLogoSmall}
          alt='Automation Center Logo'
          className={Style.automationCenterLogo}
        />
      </button>
      {isDesktop && canSearchAutomation && (
        <div className={Style.searchBox}>
          <FormInput
            type='text'
            placeholder={`Search`}
            onChange={(e) => handleChange(e)}
            value={searchValue}
            onKeyDown={(e) => handleSearch(e)}
            cssClass={Style.searchInput}
          />
          <Icon className={Style.icon} type='search' outlined />
        </div>
      )}
      {canSearchAutomation && (
        <div className={discoverClasses} onClick={handleDiscover} role='none'>
          <Icon type='star_outline' outlined /> {isDesktop && <span>Discover</span>}
        </div>
      )}
      {(canViewAllAutomationApprovals || canViewAutomationApprovals || canViewContributionList) && (
        <div className={manageClasses} onClick={handleManage} role='none'>
          <Icon type='display_settings' outlined /> {isDesktop && <span>Manage</span>}
          {isApprover && approvals !== 0 && (canViewAutomationApprovals || canViewAllAutomationApprovals) && (
            <Pill size='small' cssClass={Style.approvalsPill}>
              {approvals ?? <Loader cssClass={Style.loader} size={'extra small'} />}
            </Pill>
          )}
        </div>
      )}
      {canUploadAutomation && (
        <div className={uploadClasses} onClick={handleUpload} role='none'>
          <Icon type='file_upload' outlined /> {isDesktop && <span>Upload</span>}
        </div>
      )}

      {canViewApplicationInsights && (
        <div className={uploadClasses} onClick={handleApplicationInsights} role='none'>
          <Icon type='analytics' outlined /> {isDesktop && <span>Application Insights</span>}
        </div>
      )}

      {canViewLearnPage && (
        <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(LEARN_URL)} role='none'>
          <Icon type='emoji_objects' outlined /> {isDesktop && <span>Learn</span>}
        </div>
      )}
      {canViewAutomationNetwork && (
        <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(AUTOMATION_NETWORK_URL)} role='none'>
          <Icon type='people_alt' outlined /> {isDesktop && <span>Automation Network</span>}
        </div>
      )}
      {canViewPropellerPage && (
        <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(PROPELLER_URL)} role='none'>
          <Icon type='wind_power' outlined /> {isDesktop && <span>Propeller</span>}
        </div>
      )}
      {canViewReleaseNotesPage && (
        <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(RELEASE_NOTES_URL)} role='none'>
          <Icon type='speaker_notes' outlined /> {isDesktop && <span>Release Notes</span>}
        </div>
      )}
      {canViewFAQPage && (
        <div className={staticMenuClasses} onClick={() => handleStaticMenuClick(FAQ_URL)} role='none'>
          <Icon type='quiz' outlined /> {isDesktop && <span>FAQ</span>}
        </div>
      )}
      {canViewProfilePage && (
        <div className={profileClasses} onClick={handleProfile} role='none'>
          <Icon type='account_circle' outlined /> {isDesktop && <span>Profile</span>}
        </div>
      )}

      {canViewUserList && (
        <div className={authoriserManagementClasses} onClick={handleAuthoriserManagement} role='none'>
          <Icon type='group' outlined /> {isDesktop && <span>Authoriser Management</span>}
        </div>
      )}

      <div className={Style.menuItem} onClick={handleLogout} role='none'>
        <Icon type='logout' outlined /> {isDesktop && <span>Logout</span>}
      </div>

      <div className={Style.menuFooter}>
        <div className={Style.menuVersion}>{isDesktop ? `Automation Centre v${version}` : `v${version}`}</div>
        <div className={Style.legalLinks}>
          <a className={Style.link} href={`/#/${AppRoutes.TERMS_AND_CONDITION}`} target='_blank' rel='noreferrer'>
            {isDesktop ? 'Terms & Conditions' : 'T&C'}
          </a>
          {isDesktop && <span className={Style.separator}>|</span>}
          <a className={Style.link} href={`/#/${AppRoutes.PRIVACY}`} target='_blank' rel='noreferrer'>
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  )
}

export default Menu

import Joi from 'joi'
import { AppRolesEnum } from '../config/config'

export const newApproverFormFields = {
  email: 'User',
  previousRole: 'Previous Role',
  newRole: 'New Role',
}

export const editApproverRoleFormSchema = Joi.object({
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .trim()
    .required()
    .messages({
      'any.required': 'This is a required field',
    }),
  previousRole: Joi.string()
    .required()
    .trim()
    .valid(...Object.values(AppRolesEnum))
    .messages({
      'any.only': 'Please select a valid option',
    }),
  newRole: Joi.string()
    .required()
    .trim()
    .valid(...Object.values(AppRolesEnum))
    .messages({
      'any.only': 'Please select a valid option',
    }),
})

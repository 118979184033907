import Joi from 'joi'
import { AppRolesEnum } from '../config/config'

export const newApproverFormFields = {
  email: 'User',
  role: 'Role',
}

export const addNewApproverFormSchema = Joi.object({
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .trim()
    .required()
    .messages({
      'any.required': 'This is a required field',
    }),
  role: Joi.string()
    .required()
    .trim()
    .valid(...Object.values(AppRolesEnum))
    .messages({
      'any.only': 'Please select a valid option',
    }),
})

import { FC, useState, useEffect, ChangeEvent, KeyboardEvent } from 'react'
import Style from '../styles/Search.module.sass'
import { appInsights } from '../api/AppInsights'
import { Button, Container, Grid } from '@aurecon-creative-technologies/styleguide'
import { useLocation, useParams } from 'react-router-dom'
import Page from '../components/Page'
import { buttonType } from '../enums/ButtonType'
import classNames from 'classnames'
import SearchAutomationCard from '../components/SearchAutomationCard'
import { SEARCH_PAGE_SIZE } from '../config/config'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import LoadingScreen from '../components/LoadingScreen'
import { SearchAutomationFilters } from '../stores/AppStore'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import { ApprovedAutomationsAndScripts } from '../stores/ManageAutomationStore'
import AutomationFilter from '../components/AutomationFilter'
import { actions } from '../helpers/userPermission'
import { useUserPermission } from '../hooks/useUserPermission'
import ErrorScreen from './ErrorScreen'

const Search: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Search' })
  const { searchText } = useParams<{ searchText: string }>()
  const [searchAutomationFilters, setSearchAutomationFilters] = useRecoilState(SearchAutomationFilters)
  const approvedAutomationScriptsLoader = useRecoilValueLoadable(ApprovedAutomationsAndScripts)
  const [overallAutomations, setOverallAutomations] = useState<IGetAutomationResponse[]>()
  const [activeButton, setActiveButton] = useState<string>('')
  const [automations, setAutomations] = useState<IGetAutomationResponse[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [maxPages, setMaxPages] = useState<number>(1)
  const [tempPage, setTempPage] = useState<string>('1')
  const [loading, setLoading] = useState<boolean>(false)

  const location = useLocation()

  const canViewSearchPage = useUserPermission(actions.SEARCH_AUTOMATION)

  useEffect(() => {
    if (approvedAutomationScriptsLoader.state !== 'hasValue' || !approvedAutomationScriptsLoader.contents) return

    setOverallAutomations(approvedAutomationScriptsLoader.contents)
  }, [approvedAutomationScriptsLoader.contents, approvedAutomationScriptsLoader.state])

  useEffect(() => {
    setLoading(true)
    if (!overallAutomations) return
    const searchParams = searchText ?? ''

    const filteredAutomations = overallAutomations.filter(
      (a) =>
        a.Title.toLowerCase().includes(searchParams.trim().toLowerCase()) ||
        a.Description.toLowerCase().includes(searchParams.trim().toLowerCase()),
    )

    setAutomations(filteredAutomations)
    setMaxPages(Math.ceil(filteredAutomations.length / SEARCH_PAGE_SIZE))

    setLoading(false)
  }, [location.pathname, searchAutomationFilters, searchText, setSearchAutomationFilters, overallAutomations])

  const matchesFilter = (automation: IGetAutomationResponse): boolean => {
    const matchSources =
      !searchAutomationFilters.source.length ||
      automation.AutomationSources?.some((source) =>
        searchAutomationFilters.source.some(
          (selected) => selected.label?.toString().toLowerCase() === source.Name.toLowerCase(),
        ),
      )
    const matchCapabilities =
      !searchAutomationFilters.capability.length ||
      automation.AutomationCapabilities?.some((capability) =>
        searchAutomationFilters.capability.some(
          (selected) => selected.label?.toString().toLowerCase() === capability.Name.toLowerCase(),
        ),
      )
    const matchPractises =
      !searchAutomationFilters.practice.length ||
      automation.AutomationPractices?.some((practice) =>
        searchAutomationFilters.practice.some(
          (selected) => selected.label?.toString().toLowerCase() === practice.Name.toLowerCase(),
        ),
      )
    const matchRegions =
      !searchAutomationFilters.region.length ||
      automation.AutomationRegions?.some((region) =>
        searchAutomationFilters.region.some(
          (selected) => selected.label?.toString().toLowerCase() === region.Name.toLowerCase(),
        ),
      )
    return (matchSources && matchCapabilities && matchPractises && matchRegions) || false
  }

  const startIndex = (currentPage - 1) * SEARCH_PAGE_SIZE
  const endIndex = startIndex + SEARCH_PAGE_SIZE
  const filteredAutomations = automations.filter(matchesFilter)
  const limitedAutomations = filteredAutomations.slice(startIndex, endIndex)

  useEffect(() => {
    setMaxPages(Math.ceil(filteredAutomations.length / SEARCH_PAGE_SIZE))
    setCurrentPage(1)
    if (Math.ceil(filteredAutomations.length / SEARCH_PAGE_SIZE) == 0) {
      setTempPage('0')
    } else {
      setTempPage('1')
    }
  }, [filteredAutomations.length])

  function handleClick(buttonId: string) {
    setActiveButton(buttonId)
    if (buttonId == buttonType.START_BUTTON) {
      setCurrentPage(1)
      setTempPage('1')
    }
    if (buttonId == buttonType.FORWARD_BUTTON) {
      setCurrentPage(currentPage + 1)
      setTempPage((currentPage + 1).toString())
    }
    if (buttonId == buttonType.BACK_BUTTON) {
      setCurrentPage(currentPage - 1)
      setTempPage((currentPage - 1).toString())
    }
    if (buttonId == buttonType.END_BUTTON) {
      setCurrentPage(maxPages)
      setTempPage(maxPages.toString())
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (tempPage != '') {
        setCurrentPage(Number(tempPage))
      }
    }
  }

  const handlePageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const pageInput = Number(event.target.value)
    if (pageInput >= maxPages) {
      setTempPage(maxPages.toString())
    } else {
      setTempPage(event.target.value)
    }
  }

  const buttonClasses = (buttonId: string): string => {
    return classNames({
      [Style.active]: activeButton === buttonId,
      [Style.pageButtons]: true,
    })
  }

  const cardClasses = classNames({
    [Style.sectionDefault]: limitedAutomations.length < 4,
    [Style.section]: limitedAutomations.length >= 4,
  })

  if (!canViewSearchPage) return <ErrorScreen title='401' description='Unauthorized Access' />

  return (
    <Page menu>
      {loading ? (
        <div className={Style.searchLoader}>
          <LoadingScreen size='extra small' text={searchText ? 'Loading search results' : 'Loading automations'} />
        </div>
      ) : (
        <Container cssClass={Style.layoutContainer} fluid>
          <header className={Style.hero}>
            {searchText ? <h2>Results for "{searchText}"</h2> : <h2>Discover automations</h2>}
          </header>
          <AutomationFilter cssClass={Style.filters} filterState={SearchAutomationFilters} />
          <Grid row xs={12} gap={12} cssClass={cardClasses}>
            {limitedAutomations.map((automation) => (
              <SearchAutomationCard automation={automation} key={automation.Id} />
            ))}
          </Grid>
          {limitedAutomations.length > 0 ? (
            <div className={Style.sectionTitle}>
              <div className={Style.pageReference}>
                <p>
                  Page <input type='text' value={tempPage} onChange={handlePageChange} onKeyDown={handleKeyDown} />
                  of {maxPages}
                </p>
              </div>
              <div className={Style.pageButtonsContainer}>
                <Button
                  onClick={() => handleClick(buttonType.START_BUTTON)}
                  type='icon-round-secondary'
                  size='medium'
                  icon='keyboard_double_arrow_left'
                  disabled={currentPage === 1}
                  cssClass={Style.pageButtons}
                />
                <Button
                  onClick={() => handleClick(buttonType.BACK_BUTTON)}
                  type='icon-round-secondary'
                  size='medium'
                  icon='keyboard_arrow_left'
                  disabled={currentPage === 1}
                  cssClass={buttonClasses(buttonType.BACK_BUTTON)}
                />
                <Button
                  onClick={() => handleClick(buttonType.FORWARD_BUTTON)}
                  type='icon-round-secondary'
                  size='medium'
                  icon='keyboard_arrow_right'
                  disabled={currentPage === maxPages}
                  cssClass={buttonClasses(buttonType.FORWARD_BUTTON)}
                />
                <Button
                  onClick={() => handleClick(buttonType.END_BUTTON)}
                  type='icon-round-secondary'
                  size='medium'
                  icon='keyboard_double_arrow_right'
                  disabled={currentPage === maxPages}
                  cssClass={Style.pageButtons}
                />
              </div>
            </div>
          ) : (
            <p className={Style.noResultText}>There are no results that match your search</p>
          )}
        </Container>
      )}
    </Page>
  )
}

export default Search

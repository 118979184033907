import { FC } from 'react'
import Page from '../components/Page'
import { appInsights } from '../api/AppInsights'

interface IErrorScreenProps {
  title?: string
  description?: string
}

const ErrorScreen: FC<IErrorScreenProps> = (props) => {
  if (appInsights) appInsights.trackPageView({ name: 'ErrorScreen' })

  return (
    <Page footer>
      <div className='theme-navbar-text' style={{ textAlign: 'center' }}>
        <h1>{props.title ?? 'Error occured'}</h1>
        {props.description && <h3>{props.description}</h3>}
      </div>
    </Page>
  )
}

export default ErrorScreen

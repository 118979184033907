import {
  IButtonProps,
  Icon,
  IHeader,
  Modal,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide'
import { FC, useCallback, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import Style from '../../../styles/common/modals/PermissionHistoryModal.module.sass'
import { PermissionHistory, ShowPermissionHistoryModal } from '../../../stores/UserStore'
import LoadingScreen from '../../LoadingScreen'
import { IGetPermissionHistoryResponse } from '../../../models/api/IUserRequestModel'
import { PAGE_SIZE } from '../../../config/config'
import { AppRoleLabel, AppRoles } from '../../../enums/AppRoles'
import { dateToString } from '../../../helpers/utils'
import CustomPagination from '../CustomPagination'

const PermissionHistoryModal: FC = () => {
  const permissionHistory = useRecoilValueLoadable(PermissionHistory)
  const [showPermissionHistoryModal, setShowPermissionHistoryModal] = useRecoilState(ShowPermissionHistoryModal)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [userSort, setUserSort] = useState('none')
  const [emailSort, setEmailSort] = useState('none')
  const [actionSort, setActionSort] = useState('none')
  const [actionedBySort, setActionedBySort] = useState('none')
  const [dateSort, setDateSort] = useState('desc')
  type sortFields = 'user' | 'email' | 'action' | 'actionedBy' | 'date'

  const handleClose = () => {
    setShowPermissionHistoryModal(false)
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
  ]

  const tableHeaders = [
    {
      label: 'User',
      sort: userSort,
      onSort: (sortString: string) => handleSort(sortString, 'user'),
    },

    {
      label: 'User Email',
      sort: emailSort,
      onSort: (sortString: string) => handleSort(sortString, 'email'),
    },
    {
      label: 'Action',
      sort: actionSort,
      onSort: (sortString: string) => handleSort(sortString, 'action'),
    },
    {
      label: 'Actioned By',
      sort: actionedBySort,
      onSort: (sortString: string) => handleSort(sortString, 'actionedBy'),
    },
    {
      label: 'Actioned On',
      sort: dateSort,
      onSort: (sortString: string) => handleSort(sortString, 'date'),
    },
  ] as IHeader[]

  const handleSort = useCallback((sortString: string, type: sortFields) => {
    setUserSort(type === 'user' ? sortString : 'none')
    setEmailSort(type === 'email' ? sortString : 'none')
    setActionSort(type === 'action' ? sortString : 'none')
    setActionedBySort(type === 'actionedBy' ? sortString : 'none')
    setDateSort(type === 'date' ? sortString : 'none')
  }, [])

  const historySorted = useMemo(() => {
    const history = permissionHistory.state === 'hasValue' ? [...permissionHistory.contents] : []

    if (userSort !== 'none') {
      return history.sort((a: IGetPermissionHistoryResponse, b: IGetPermissionHistoryResponse) => {
        const sort = userSort === 'asc' ? 1 : -1
        return a.UpdatedUser.UserFullName?.localeCompare(b.UpdatedUser.UserFullName) * sort
      })
    }

    if (emailSort !== 'none') {
      return history.sort((a: IGetPermissionHistoryResponse, b: IGetPermissionHistoryResponse) => {
        const sort = emailSort === 'asc' ? 1 : -1
        return a.UpdatedUser.UserEmail?.localeCompare(b.UpdatedUser.UserEmail) * sort
      })
    }

    if (actionSort !== 'none') {
      return history.sort((a: IGetPermissionHistoryResponse, b: IGetPermissionHistoryResponse) => {
        const sort = actionSort === 'asc' ? 1 : -1
        return a.PreviousRole.Name?.localeCompare(b.PreviousRole.Name) * sort
      })
    }

    if (actionedBySort !== 'none') {
      return history.sort((a: IGetPermissionHistoryResponse, b: IGetPermissionHistoryResponse) => {
        const sort = actionedBySort === 'asc' ? 1 : -1
        return a.UpdatedByUser.UserFullName?.localeCompare(b.UpdatedByUser.UserFullName) * sort
      })
    }

    if (dateSort !== 'none') {
      return history.sort((a: IGetPermissionHistoryResponse, b: IGetPermissionHistoryResponse) => {
        const sort = dateSort === 'asc' ? 1 : -1
        return a.DateCreated?.localeCompare(b.DateCreated) * sort
      })
    }

    return history
  }, [actionSort, actionedBySort, dateSort, emailSort, permissionHistory.contents, permissionHistory.state, userSort])

  const pageCount = permissionHistory.contents.length / PAGE_SIZE
  const maxPages = Math.ceil(pageCount > 0 ? pageCount : 1)

  const roleLabel = useMemo(() => {
    const roleLabels = Object.fromEntries(
      Object.entries(AppRoles).map(([key, val]) => [val, AppRoleLabel[AppRoles[key as keyof typeof AppRoles]]]),
    )
    return (role: string) => roleLabels[role]
  }, [])

  return (
    <Modal
      isShowing={showPermissionHistoryModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='large'
      cssClass={Style.permissionHistoryModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <h2>Permission History</h2>
        </div>
        <div className={Style.modalContent}>
          <div className={Style.tableContainer}>
            {permissionHistory.state === 'loading' ? (
              <LoadingScreen size='medium' text='Loading...' />
            ) : (
              <Table cssClass={Style.usersTable} headers={tableHeaders}>
                {historySorted.length > 0 ? (
                  historySorted
                    .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
                    .map((history: IGetPermissionHistoryResponse) => (
                      <TableRow key={history.Id} rowClass={Style.tableRow}>
                        <TableCell cellClass={Style.userName}>{history.UpdatedUser.UserFullName}</TableCell>
                        <TableCell cellClass={Style.userEmail}>{history.UpdatedUser.UserEmail}</TableCell>
                        <TableCell cellClass={Style.action}>
                          <div className={Style.actionHistory}>
                            <span>{roleLabel(history.PreviousRole.Name)}</span>
                            <Icon type='arrow_forward' size='small' outlined />
                            <span>{roleLabel(history.NewRole.Name)}</span>
                          </div>
                        </TableCell>
                        <TableCell cellClass={Style.updatedBy}>{history.UpdatedByUser.UserFullName}</TableCell>
                        <TableCell cellClass={Style.dateCreated}>
                          {dateToString(history.DateCreated, 'MM/dd/yyyy hh:mm a')}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow rowClass={Style.tableRow}>
                    <TableCell colSpan={7} align='right' style={{ textAlign: 'center', padding: '30px' }}>
                      No available data
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            )}
          </div>
          <CustomPagination currentPage={currentPage} setCurrentPage={setCurrentPage} maxPages={maxPages} />
        </div>
      </>
    </Modal>
  )
}

export default PermissionHistoryModal
